var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 py-8"},[_c('div',{staticClass:"text-h5 font-weight-bold grey--text text--darken-2 mb-4"},[_c('v-icon',[_vm._v("mdi-new-box")]),_vm._v(" Create New KRI Coverage ")],1),_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.e6),callback:function ($$v) {_vm.e6=$$v},expression:"e6"}},[_c('v-stepper-step',{attrs:{"complete":_vm.e6 > 1,"step":"1"}},[_vm._v(" Select a Date Range ")]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',{staticClass:"pl-4"},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"return-value":_vm.startDate,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.startDate=$event},"update:return-value":function($event){_vm.startDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":"","min":_vm.getMin},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.startDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-row',{staticClass:"pl-4"}),_c('v-row',{staticClass:"pt-4 pl-4"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.e6 = 2}}},[_vm._v(" Continue ")])],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.e6 > 2,"step":"2"}},[_vm._v(" Select ORMP Rows ")]),(this.ORMPLevels.length === 0)?_c('v-stepper-content',{attrs:{"step":"2"}},[_c('p',[_vm._v("There are no ORMP's available to replicate.")]),(this.ORMPLevels.length === 0)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.saveDateRange}},[_vm._v(" Save ")]):_vm._e(),_c('v-btn',{staticClass:"ml-1",on:{"click":function($event){_vm.e6 -= 1}}},[_vm._v(" Back ")])],1):_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.returnORMPs,"single-expand":"","show-expand":"","item-key":".key","show-select":""},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item-title',[_c('h4',[_vm._v("Objectives")])]),_vm._l((item.Objectives),function(cause,index){return _c('v-list',{key:index},[_c('v-list-item',[_vm._v(_vm._s(cause))])],1)})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item-title',[_c('h4',[_vm._v("Causes")])]),_vm._l((item.Causes),function(cause,index){return _c('v-list',{key:index},[_c('v-list-item',[_vm._v(_vm._s(index+1)+" - "+_vm._s(cause))])],1)})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item-title',[_c('h4',[_vm._v("Impacts")])]),_vm._l((item.Impacts),function(impact,index){return _c('v-list',{key:index},[_c('v-list-item',[_vm._v(_vm._s(impact))])],1)})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item-title',[_c('h4',[_vm._v("Existing Risk Action for Causes")])]),_vm._l((item.RiskActions),function(riskaction,index){return _c('div',{key:index},[_c('v-list-item',[_c('v-col',_vm._l((riskaction.causes),function(cause,index){return _c('span',{key:index},[_vm._v(_vm._s(cause.value+1)),(index != (riskaction.causes.length - 1))?_c('span',[_vm._v(",")]):_vm._e()])}),0),_c('v-col',[_vm._v(_vm._s(riskaction.text))])],1)],1)})],2)],1)],1)]}},{key:"item.information",fn:function(ref){
var item = ref.item;
return [(_vm.levelStatusTime(item))?_c('v-chip',{staticClass:"mt-1",attrs:{"color":"green","text-color":"white"}},[_vm._v(_vm._s(_vm.levelStatusTime(item))+" ")]):_vm._e()]}},{key:"item.AffectedStakeholders",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.AffectedStakeholders),function(stakeholder,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(stakeholder.AffectedStakeholder)+" ")])}),0)]}},{key:"item.RiskTreatmentOwner",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.RiskTreatmentOwner),function(riskowner,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(riskowner.RiskTreatmentOwner)+" ")])}),0)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"mt-4"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selected.length == 0},on:{"click":_vm.saveCoverage}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"ml-1",on:{"click":function($event){_vm.e6 -= 1}}},[_vm._v(" Back ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }