<template>
    <v-container class="pa-4 py-8">
        <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-4">
            <v-icon>mdi-new-box</v-icon>
            Create New KRI Coverage
        </div>

        <v-stepper
            v-model="e6"
            vertical
            >
            <v-stepper-step
                :complete="e6 > 1"
                step="1"
                >
                Select a Date Range
            </v-stepper-step>

            <v-stepper-content step="1">
                <v-row class="pl-4">
                    <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        :return-value.sync="startDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="startDate"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            type="month"
                            v-model="startDate"
                            no-title
                            scrollable
                            :min="getMin"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu1 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu1.save(startDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-row>

                <v-row class="pl-4">
                     <!-- <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :return-value.sync="endDate"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="endDate"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="endDate"
                            no-title
                            scrollable
                            :allowed-dates="allowedDatesEnd"
                            :min="getMin"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu2 = false"
                                >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu2.save(endDate)"
                                >
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu> -->
                </v-row>

                <v-row class="pt-4 pl-4">
                    <v-btn
                        color="primary"
                        @click="e6 = 2"
                        >
                        Continue
                    </v-btn>
                </v-row>
            </v-stepper-content>

            <v-stepper-step
                :complete="e6 > 2"
                step="2"
                >
                Select ORMP Rows
            </v-stepper-step>
            <v-stepper-content
             step="2"
             v-if="this.ORMPLevels.length === 0"
             >
             <p>There are no ORMP's available to replicate.</p>
                 <v-btn
                    v-if="this.ORMPLevels.length === 0"
                    color="primary"
                    @click="saveDateRange"
                    >
                    Save
                </v-btn>
                <v-btn
                    class="ml-1"
                    @click="e6 -= 1"
                    >
                    Back
                </v-btn>
            </v-stepper-content>

            <v-stepper-content step="2" v-else>
                <v-data-table
                    v-model="selected"
                    :headers="headers"
                    :items="returnORMPs"
                    single-expand
                    show-expand
                    item-key=".key"
                    show-select
                    class="elevation-1"
                    >
                    <template v-slot:expanded-item="{ item }">
                        <td colspan="12">
                        <v-row>
                            <v-col cols="3"> 
                            <v-list-item-title><h4>Objectives</h4></v-list-item-title>
                            <v-list 
                                v-for="(cause, index) in item.Objectives"
                                :key="index"
                                ><v-list-item>{{ cause }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3"> 
                            <v-list-item-title><h4>Causes</h4></v-list-item-title>
                            <v-list 
                                v-for="(cause, index) in item.Causes"
                                :key="index"
                                ><v-list-item>{{index+1}} - {{ cause }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3">
                            <v-list-item-title><h4>Impacts</h4></v-list-item-title>
                                <v-list 
                                v-for="(impact, index) in item.Impacts"
                                :key="index"
                                ><v-list-item>{{ impact }}</v-list-item>
                            </v-list>
                            </v-col>
                            
                            <v-col cols="3">
                                <v-list-item-title><h4>Existing Risk Action for Causes</h4></v-list-item-title>
                                <div v-for="(riskaction, index) in item.RiskActions"
                                    :key="index">
                                    <v-list-item> 
                                    <v-col><span v-for="(cause, index) in riskaction.causes"
                                        :key="index">{{cause.value+1}}<span v-if="index != (riskaction.causes.length - 1)">,</span></span> 
                                    </v-col>
                                    <v-col>{{riskaction.text}}</v-col>
                                    </v-list-item>
                                </div>
                            </v-col>
                        </v-row>
                        </td>
                    </template>

                    <template v-slot:item.information="{ item }">
                        <v-chip 
                            color="green" 
                            text-color="white" 
                            class="mt-1" 
                            v-if="levelStatusTime(item)"
                            >{{levelStatusTime(item)}}
                        </v-chip>
                    </template>

                    <template v-slot:item.AffectedStakeholders="{ item }">
                        <td>
                        <div v-for="(stakeholder, index) in item.AffectedStakeholders"
                            :key="index">
                            {{stakeholder.AffectedStakeholder}}
                        </div>
                        </td>
                    </template>

                    <template v-slot:item.RiskTreatmentOwner="{ item }">
                        <td>
                        <div
                            v-for="(riskowner, index) in item.RiskTreatmentOwner"
                            :key="index">
                            {{riskowner.RiskTreatmentOwner}}
                        </div>
                        </td>
                    </template>

                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="initialize"
                            >
                            Reset
                        </v-btn> 
                    </template>
                </v-data-table>

                <div class="mt-4">
                    <v-btn
                        color="primary"
                        @click="saveCoverage"
                        :disabled="selected.length == 0"
                        >
                        Save
                    </v-btn>
                    <v-btn
                        class="ml-1"
                        @click="e6 -= 1"
                        >
                        Back
                    </v-btn>
                </div>
            </v-stepper-content>

        </v-stepper>
    </v-container>
</template>

<script>
import moment from 'moment';
export default {
    data() {
        return {
            e6: 1,
            coverageID: '',
            startDate: null,
            endDate: null,
            menu1: false,
            menu2: false,
            selected: [],
            headers: [
                { text: 'Risk Name', value: 'RiskName.RiskName' },
                {
                    text: 'Risk Classification',
                    align: 'start',
                    sortable: true,
                    value: 'RiskClassification.RiskClassification',
                },
                { text: 'Affected Stakeholders', value: 'AffectedStakeholders' },
                { text: 'Risk Treatment Owner', value: 'RiskTreatmentOwner' },
                { text: 'Likelihood', value: 'LikelihoodLevel.Level' },
                { text: 'Impact', value: 'ImpactLevel.Level' },
                { text: 'Impact Classification', value: 'ImpactClassification.name' },
                { text: 'Vulnerability', value: 'Vulnerability' },
                // { text: 'Risk Strategy', value: 'RiskStrategy.RiskStrategy' },
                // { text: 'Information', value: 'information' },
            ],
        }
    },
    created() {
        this.setDefaultDate()
        this.initialize()
    },
    firestore(){
      return {
        ORMPs: this.$db.collection('ORMP'),
        ORMPCoverage: this.$db.collection('ORMPCoverage'),
        KRICoverage: this.$db.collection('KRICoverage'),
        ORMPLevels: this.$db.collection('ORMPLevels').where('RiskManagementLevel','==','Residual'),
        Departments: this.$db.collection('Departments'),
        AffectedStakeholder: this.$db.collection('AffectedStakeholder'),
      }
    },

    methods: {
        initialize () {
            this.desserts = this.ORMPLevels.filter(a=>{
                return this.deptFilter == a.Department
            })
        },
        getCoverageDate(key){
            console.log(key);
            return this.ORMPCoverage.filter(a=>{
                return a['.key'] == key
            })[0]
        },
        async saveDateRange() {
             let data = {
                startDate: this.startDate,
                // endDate: this.endDate,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department,
            }
            let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new KRI Coverage?`,
                title: `Confirm New KRI Coverage`
            })
            if(confirm){
                self.$db.collection('KRICoverage').add(data).then(async (doc)=>{
                    let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:'KRICoverage',documentKey:id,module: 'KRI',action: 'Added New KRI Coverage'})  
                    self.$dialog.notify.success(`Added New KRI Coverage`, {
                        position: 'bottom-right',
                        timeout: 3000
                      })      
                      self.$router.push('/viewallkri/'+id)      
                    })    
            }
        },
        async saveCoverage() {


            let data = {
                startDate: this.startDate,
                endDate: this.endDate,
                CreatedAt: this.$firebase.firestore.FieldValue.serverTimestamp(),
                CreatedBy: this.$store.getters['useraccount/isAuthenticated'].email,
                Department: this.$store.getters['useraccount/isAuthenticated'].department,
            }
            let self = this
             const confirm = await this.$dialog.confirm({
                text: `Do you really want to save this new KRI Coverage?`,
                title: `Confirm New KRI Coverage`
            })

            if(confirm){
                  self.$db.collection('KRICoverage').add(data)
                 .then(async(doc)=>{
                    let id = doc.id
                    await self.$store.dispatch('logs/createNotifs', {collection:'KRICoverage',documentKey:id,module: 'KRI',action: 'Added New KRI Coverage'}) 
                    this.coverageID = doc.id
                    let selected = this.selected
                    selected.forEach(e => {
                        let x = {...e}
                        delete x['.key']
                        x.coverageId = id
                        x.CreatedAt = this.$firebase.firestore.FieldValue.serverTimestamp()
                        x.CreatedBy = this.$store.getters['useraccount/isAuthenticated'].email
                        x.Department = this.$store.getters['useraccount/isAuthenticated'].department

                     this.$db.collection('KRI-ORMP').add(x)
                    .then(async(doc) => {
                        let ORMPId = doc.id 
                        await self.$store.dispatch('logs/createNotifs', {collection:'KRI-ORMP',documentKey:ORMPId,module: 'KRI',action: 'Added New KRI-ORMP'}) 
                                       
                    })  
                    self.$dialog.notify.success(`Added New KRI Coverage`, {
                            position: 'bottom-right',
                            timeout: 3000
                    })
                    self.$router.push('/viewallkri/' + this.coverageID)  
                 })
                })
            }
        },
        setDefaultDate(){
            this.startDate = this.getMin
            this.endDate = this.getMin
        },

        levelStatusTime(item) {
        let hello = []
        let filter = this.ORMPLevels.filter(e => {
         return e.ORMPId === item['.key'] 
        })
          filter.forEach(e=> {
            hello.push({RiskManagementLevel: e.RiskManagementLevel, CreatedAt: e.CreatedAt})
          })
          let order = this.$lodash.orderBy(hello, ['CreatedAt'], ['desc'])
          let mapped = order.map(e=> {
            return e.RiskManagementLevel + " " + 'created on' + " " + moment(e.CreatedAt.toDate()).format(' MMMM DD, YYYY / h:mm A')
          })
          return mapped[0]
      },
      isSameOrAfterAndBefore(date,start,end){
          return this.$moment(date).isSameOrAfter(start) && this.$moment(date).isSameOrBefore(end) //not sure if perfect works at first.
      },
      isDateinKRICoverage(value){
          console.log(value,value);
          let cov = this.KRICoverage.filter(a=>{
              let KRIstartDate = a.startDate
              let KRIendDate = a.endDate
              return this.$moment(value).isBetween(KRIstartDate,KRIendDate) || this.isSameOrAfterAndBefore(value,KRIstartDate,KRIendDate)
          })
          return cov.length > 0
      },
      allowedDates(val) {
          return !this.isDateinKRICoverage(val)
      },
      allowedDatesEnd(val) {
          return !this.isDateinKRICoverage(val) && val > this.startDate
      },
    },
    computed: {
        getMin() {
            // var d = new Date(new Date().getFullYear() + 1, 0, 2);
            // return d.toISOString().substring(0, 7);

            var d = new Date(new Date().getFullYear(), 0, 2);
            return d.toISOString().substring(0, 10)
        },

        getMax() {
            //  var d = new Date(new Date().getFullYear() + 1, 11, 2);
            // return d.toISOString().substring(0, 7);

            var d = new Date(new Date().getFullYear() + 1, 11, 2);
            return d.toISOString().substring(0, 10)
        },

        returnORMPs(){
        let before = this.ORMPLevels

        console.log(before,'bedfore');
        let self = this

        let filtered = []

        //get KRI START
        //get KRI END
        let KRIstartDate = this.startDate
        // let KRIendDate = this.endDate

        //get coverage of ORMP LEVEL
        //if KRI START AND END
        //is equal or between the ORMP coverage start end
        //show in choices


        this.ORMPs.forEach(a=>{
            before.forEach(x=>{
                if(a['.key'] == x.ORMPId){
                    console.log('here');
                    let obj = {...a}
                    let push = {...x}
                    push.AffectedStakeholders = obj.AffectedStakeholders
                    push.RiskClassification = obj.RiskClassification
                    push.RiskName = obj.RiskName

                    let {startDate} = this.getCoverageDate(push.CoverageId)
                    console.log(this.getCoverageDate(push.CoverageId),'coverage');
                    console.log(this.$moment(startDate).format('MMM YYYY'),'startDate');
                    console.log(this.$moment(KRIstartDate).format('MMM YYYY'),'KRI SET');

                    // console.log(this.$moment(startDate).isBetween(KRIstartDate,KRIendDate),'isBetween');
                    // console.log(this.$moment(endDate).isBetween(KRIstartDate,KRIendDate),'isBetween');
                    // console.log(this.isSameOrAfterAndBefore(startDate,KRIstartDate,KRIendDate),'isSameOrAfterAndBefore - StartDate');
                    // console.log(this.isSameOrAfterAndBefore(endDate,KRIstartDate,KRIendDate),'isSameOrAfterAndBefore - ENDate');
                    if(this.$moment(startDate).format('MMM YYYY') == this.$moment(KRIstartDate).format('MMM YYYY')){
                    // if(this.$moment(startDate).isBetween(KRIstartDate,KRIendDate) || this.isSameOrAfterAndBefore(startDate,KRIstartDate,KRIendDate) && this.$moment(endDate).isBetween(KRIstartDate,KRIendDate) || this.isSameOrAfterAndBefore(endDate,KRIstartDate,KRIendDate)){
                        // console.log('PUSH RESIDUAL',startDate,endDate)
                        console.log(this.$moment(startDate).format('MMM YYYY'),'startDate');
                        console.log(this.$moment(KRIstartDate).format('MMM YYYY'),'KRI SET');

                        filtered.push(push)
                    }
                  
                }
            })
        })


        let user = self.$store.getters['useraccount/isAuthenticated']
            return self.$lodash.uniqBy(filtered.filter(a=>{
                return a.Department == user.department
            }), e=>{
                return e.RiskName.RiskName
            })          
        
      },

    },

}
</script>